
$( document ).ready(function() {
  // console.log('hi');
// $('.refresh').change(function() {
//   // console.log('hi from refresh');
//   $("#events-filter").submit();
// });
$('[type="reset"]').click(function(e){
  e.preventDefault(); //We don't want the reset function to fire normally.
   var $this = $(this),
     $form = $this.parent('form'),
     $input = $form.find(':input:not(:submit):not(:reset)');
   $input.each((i, item) => {
     var $item = $(item);

     if ($item.is(':checkbox')||$item.is(':radio')) {
       $item.prop('checked', false);
     } else {
       $item.val('');
     }
}).promise().done( function(){ $("#events-filter").submit(); } );
});
});
